import React from "react";
// @material-ui/core components
import withStyles from "@material-ui/core/styles/withStyles";

// @material-ui/icons
import Face from "@material-ui/icons/Face";
import Chat from "@material-ui/icons/Chat";
import Build from "@material-ui/icons/Build";
import VerifiedUser from "@material-ui/icons/VerifiedUser";
import Fingerprint from "@material-ui/icons/Fingerprint";
// core components
import GridContainer from "components/Grid/GridContainer.jsx";
import GridItem from "components/Grid/GridItem.jsx";
import InfoArea from "components/InfoArea/InfoArea.jsx";
import Divider from '@material-ui/core/Divider';
import Paper from "@material-ui/core/Paper";
import CustomTabs from "../../../components/CustomTabs/CustomTabs.jsx";

import productStyle from "assets/jss/material-kit-react/views/landingPageSections/productStyle.jsx";
import tabsStyle from "assets/jss/material-kit-react/views/componentsSections/tabsStyle.jsx";
import { Grid } from "@material-ui/core";

class VisionMissionSection extends React.Component {
  render() {
    const { classes } = this.props;
    return (
      // <div style={{background:"linear-gradient(180deg, rgba(42,63,154,1) 0%, rgba(182,161,158,1) 50%, rgba(182,161,158,1) 100%)"}}>
      <div className={classes.section} style={{textAlign:'left'}}>
        <GridContainer justify="center" alignItems="letf">
          <GridItem xs={12} sm={5} style={{}}>
            <h2 className={classes.titlewhite} style={{fontSize:"40px"}}>OUR VISION</h2>
            <br/>
            <h2 className={classes.titlewhite} style={{paddingLeft:"10px", fontSize: "30px"}}>
                ALIGN, TRANSFORM, OPTIMIZE
            </h2>
            <h3 className={classes.titlewhite} style={{paddingLeft:"30%", margin:"0"}}>
                YOUR BUISNESS
            </h3>
            <h3 className={classes.titlewhite} style={{paddingLeft:"50%", margin:"0"}}>
                USING TECHNOLOGY
            </h3>
          </GridItem>
          {/* <Divider orientation="vertical" flexItem style={{minHeight: "100% !important", border:"1px solid black"}} /> */}
          <hr orientation="vertical" style={{border:"0.2px solid grey", margin:"0"}}></hr>
          <GridItem xs={12} sm={5} >
            <h2 className={classes.titlewhite} style={{fontSize:"40px"}}>OUR MISSION</h2>
            <h4 className={classes.descriptionwhite}>
                Our Ambition
            </h4>
            <h5 className={classes.titlewhite} style={{paddingLeft:"20px", margin:"0"}}>
                LATEST TOOLS & TECHNOLOGY
            </h5>
            <h4 className={classes.descriptionwhite}>
                Our Goal
            </h4>
            <h5 className={classes.titlewhite} style={{paddingLeft:"20px", margin:"0"}}>
                DELIVERING A UNIQUE CUSTOMER EXPERIENCE WITH INNOVATIVE SOLUTIONS
            </h5>
            <h4 className={classes.descriptionwhite}>
                Our Promise
            </h4>
            <h5 className={classes.titlewhite} style={{paddingLeft:"20px", margin:"0"}}>
                GET THE BEST PRODUCT AND TEAM AT YOUR SERVICE
            </h5>
          </GridItem>
        </GridContainer>
      </div>      
    );
  }
}

export default withStyles(productStyle)(VisionMissionSection);
